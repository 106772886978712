@import "./mix.scss";


.personal-info{
    @include mix_padding(65px, 15px, 65px,
        15px);
        h2{
            @include heading(40px, 100%, 700);
            @include mix_margin(0, 0, 65px, 0);
        }
        .personalBox{
            @include mix_margin(15px, 15px, 15px, 15px);
            img{
                @include mix_margin(0 ,0 ,20px, 0)
            }
            h3{
                @include heading(1.5rem, 100%, 700);
                @include mix_margin(0, 0, 10px, 0);
            }
            p{
                font-size: 1.125rem;
                line-height: 1.5rem;
            }
        }
        .link_button{
            @include mix_margin(50px, 0, 60px, 0);
            a{
                @include bgColor($baseColor);
                font-size: 22px;
                color: #fff;
                border-radius: 50px;
                font-weight: 600;
                @include mix_padding(10px, 40px, 10px, 40px);
                line-height: 100%;
                &:hover{
                    @include bgColor(#194185);
                }
            }
            a:hover{
                color: #fff;
                cursor: pointer;
            }
        }
        .iconBox{
            .eache-iconBox{
                @include mix_margin(20px, 0, 20px, 0);
                p{
                    @include mix_margin(0, 0, 0, 20px);
                    font-size: 1.125rem;
                    line-height: 1.7rem;
                    font-weight: 100;
                    b{
                        font-weight: 700;
                    }
                }
            }
        }
    @media(max-width: 1024px) {
        h2{
            @include heading(35px, 40px, 700);
            @include mix_margin(0, 0, 30px, 0);
        }
        .personalBox{
            h3 {
                @include heading(1.2rem, 1.5rem, 700);
                @include mix_margin(0, 0, 10px, 0);
            }
        }
    }
}