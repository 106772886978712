@import './mix.scss';

.gray-row{
    --tw-bg-opacity: 1;
    background-color: rgb(249 249 249 / var(--tw-bg-opacity));
}

tr.white-row td {
    --bs-table-accent-bg: #fff !important;
}

.wrap-data{
    word-wrap: break-word !important;
    white-space: normal !important;
}

.exposed-btn-spinner{
    display: none;
    //visibility: hidden;
}

.disableClass{
    pointer-events: none;
    opacity: 0.4;
}

// th.relative-header, th.site-header{
//    // width: 260px;
// }

.logo-img{
    display: inline-flex;
    float: left;
}

.text-exposed {
    --tw-text-opacity: 1;
    color: rgb(220 16 26 / var(--tw-text-opacity));
}

.privacy-heading{
    font-size: 3rem;
    line-height: 1;
    font-weight: 700;
    @media(max-width: 991px){
        font-size: 2rem;
    }
    @media(max-width: 767px){
        font-size: 25px;
        line-height: 30px;
    }
}
.mainFullResult{
    padding-bottom: 50px;
}

.modal{
    --bs-modal-width: 625px;

    .successMessageButton{
        text-transform: uppercase;
        letter-spacing: 1.5px;
        font-size: 20px;
        color: #fff;
        background-color: #007bff;
        border-color: #007bff;
        width: 100%
    }
    
    li {
        &::before {
          content: url('../../../images/checkmark_bullet_point.png');
          height: 16px;
          width: 16px;
          margin-right: 20px;
          -webkit-box-flex: 0;
        }
    }

    .modal-footer-text{
        margin-top: 20px;
        font-size: small;
        color: #000;
    }

    .sub-header{
        line-height: 1.2;
        font-weight: normal;
        font-size: 20px;
        margin-top: 0px;
        margin-bottom: 20px;
        font-weight: 600;
    }
}



.center-aligned{
    align-items: center;
    display: flex;
    flex-direction: column;
}

.new-scan-link,
.new-scan-link:hover
{
    text-decoration-line: underline !important;
    --tw-text-opacity: 1;
    color: rgb(203 203 203 / var(--tw-text-opacity));
    font-weight: 700;
    cursor: pointer;
    // margin-top: 45px;
}

.progressbar-container{
    display: flex;
    justify-content: center;
}

.progressBarCustom{
    h1{
        @include heading(45px, 50px, 700);
        @include mix_margin(0, 0, 20px, 0);
        @media(max-width: 991px){
            @include heading(30px, 50px, 700);
        }
        @media(max-width: 767px){
            @include heading(25px, 30px, 700);
        }
    }
    .progressContnt{
        @include mix_margin(30px, 0, 20px, 0);@include mix_padding(30px, 30px, 30px, 30px);
        border: 1px solid #ddd;
        border-top: 5px solid $baseColor;
        border-radius: 10px;
        width: 85%;
        @media(max-width: 991px){
            width: 100%;
        }
        h2{
            @include heading(20px, 30px, 700);
            @include mix_margin(0, 0, 20px, 0);
        }
        .statusProgres{
            .progressbar {
                @include mix_margin(20px, 0, 20px, 0);
                @include mix_margin(15px, 0, 0, 0);
            }
            .counterBar{
                @include mix_padding(10px, 0, 0, 0);
                span{
                    color: #b3b3b3;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }
    }
}
.privacy-table-container{
    margin:0;
    border: 0px;
    .table-responsive{
        padding: 0;
    }
    .all-privacy{
        margin-bottom: 0px;
        max-width: 100%;
        border: 1px solid #ddd;
        thead{
            tr{
                th{
                    font-size: 0.875rem;
                    white-space: nowrap;
                    &:nth-child(1){
                        min-width: 250px;
                        max-width: 250px;
                        width: 250px;
                    }
                    &:nth-child(2){
                        min-width: 120px;
                        max-width: 120px;
                        width: 120px;
                    }
                    &:nth-child(3){
                        min-width: 190px;
                        max-width: 190px;
                        width: 190px;
                    }
                    &:nth-child(4){
                        min-width: 190px;
                        max-width: 190px;
                        width:190px;
                    }
                    &:nth-child(5){
                        min-width: 118px;
                        max-width: 118px;
                        width: 118px;
                    }
                    &:nth-child(6){
                        min-width: 110px;
                        max-width: 110px;
                        width: 110px;
                    }
                    &:nth-child(7){
                        min-width: 110px;
                        max-width: 110px;
                        width: 110px;
                    }
                }
            }
        }
        tbody{
            tr{
                &:not(.detailsTableCollapse):nth-child(even){
                    background: #000;
                }
                td{
                    white-space: nowrap;
                    height: 100px;
                    &:nth-child(1),
                    &:nth-child(4),
                    &:nth-child(5) {
                        white-space: normal;
                    }
                    // &:nth-child(4) {
                    //     white-space: normal;
                    // }
                    // &:nth-child(5) {
                    //     white-space: normal;
                    // }
                        
                    @include mix_padding(15px, 15px, 15px, 15px);
                    font-size: 0.875rem;
                    cursor: pointer;
                    h4{
                        font-size: 0.875rem;
                    }
                    h5{
                        font-size: 0.875rem;
                        float: left;
                    }
                    h6{
                        font-size: 0.875rem;
                    }
                    img{
                        width: 16px;
                        height: 16px;
                    }
                }
                .blur{
                    filter: blur(4px);
                }
            }
        }
        .detailsTableCollapse{
            td{
                word-wrap: break-word;
                white-space: pre-wrap;
                .collapseBox {
                    ul {
                        li {
                            border: 0px;
                        }
                    }
                }
            }
        }
    }
    .hideContainer{
        display: none;
    }
    .detailsTableCollapse{
        @include mix_padding(30px, 30px, 30px, 30px);
        //background: #f5f5f5;

        --tw-bg-opacity: 1;
        background-color: rgb(231 241 247 / var(--tw-bg-opacity));
        .collapseBox{
            h2{
                @include heading(18px, 24px, 700);
                @include mix_margin(0, 0, 15px, 0);
            }
            ul{
                @include mix_padding(0, 0, 0, 0);
                li{
                    // @include mix_margin(0, 0, 15px, 0);
                    // line-height: 24px;
                    font-size: 17px;
                }
            }
            p{
                line-height: 24px;
                font-size: 17px;
            }
        }
        .btn_rmv{
            background: #1570ef;
            font-size: 22px;
            color: #fff;
            border-radius: 50px;
            font-weight: 600;
            padding: 10px 40px 10px 40px;
            line-height: 100%;
            display: table;
            margin: 30px auto;
            &:hover{
                background: #194185;
            }
            @media(max-width: 991px){
                font-size: 16px;
                padding: 20px;
            }
        }
    }
}

// .customModalSearch{
//     background: #010632e8;
//     .modal-content{
//         background: $trans;
//         .modal-header{
//             border: 0;
//             @include mix_padding(50px, 0, 40px, 0);
//             .modal-title{
//                 color: #07c47d;
//             }
//             button.btn-close {
//                 position: fixed;
//                 right: 20px;
//                 top: 20px;
//                 z-index: 999;
//                 background-color: #fff;
//             }
//         }
//         .modal-body{
//             padding: 0;
//             .listUpBody {
//                 border: 1px solid #0E5BD8;
//                 padding: 20px;
//                 margin-bottom: 30px;
//                 color: #fff;
//                 border-radius: 5px;
//                 ul{
//                     padding-left: 0;
//                     li + li{
//                         margin-top: 10px;
//                     }
//                 }
//             }
//         }
//     }
// }

.customModalSearch {

    .modal-content {

        .modal-header {

            .modal-title {
                color: #07c47d;
            }
        }

        .modal-body {
            padding: 30px;
            .listUpBody {

                ul {
                    padding-left: 0;

                    li+li {
                        margin-top: 10px;
                    }
                }
            }
        }
    }
}

/*================== New Design =======================*/
.side-nav {
    background: #2f3135;
}

.data-container{
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
    margin-left: 0;
}

.position-relative {
    position: relative!important;
}

.side-nav .main-menu{
    color: #fff;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    cursor: pointer;
}

.side-nav .main-menu i {
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1;
}

.material-icons {
    vertical-align: middle;
}
.side-nav .main-menu h3 {
    font-weight: 400;
    font-size: 1rem;
    line-height: 1;
}

.ml-3, .mx-3 {
    margin-left: 1rem!important;
}
.mb-0, .my-0 {
    margin-bottom: 0!important;
}

.pr-5, .px-5 {
    padding-right: 3rem!important;
}
.pb-4, .py-4 {
    padding-bottom: 1.5rem!important;
}
.pt-4, .py-4 {
    padding-top: 1.5rem!important;
}
// .pl-3, .px-3 {
//     padding-left: 1rem!important;
// }

.align-items-center {
    -webkit-box-align: center!important;
    align-items: center!important;
}
.d-flex {
    display: flex!important;
}