@import "./mix.scss";

.bannerPrivacy{
    background: url(../../../images/privacy-banner.jpg) no-repeat;
    position: relative;
    z-index: 0;
    @include mix_padding(50px, 15px, 50px, 15px);
    &:after{
        position: absolute;
        content:"";
        left: 0;
        top: 0;
        width: $full;
        height: $full;
        background: $baseColor;
        opacity: 0.7;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        z-index: -1;
    }
    h1 {
        @include heading(55px, 100%, 700);
        @include mix_margin(0,0,50px,0);
    }
    h2{
        @include heading(32px, 40px, 400);
        @include mix_margin(0, 0, 2.5rem, 0);
        b{
            font-weight: 700;
        }
    }
    .banner-decription{
        @include mix_padding(80px, 0, 100px, 0);
    }
    .brandCard{
        border-radius: 20px;
        padding: 30px;
        h3{
            color: #7c828f;
            @include heading(23px, 35px, 400);
        }
    }
    @media(max-width: 1024px){
        .banner-decription{
            @include mix_padding(70px, 0, 50px, 0);
            h1{
               @include heading(35px, 100%, 700);
               @include mix_margin(0,0,30px,0); 
            }
            h2{
                @include heading(26px, 40px, 400);
                @include mix_margin(0, 0, 2.5rem, 0);
                b{
                    font-weight: 700;
                }
            }
        }
    }
}