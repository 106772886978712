@import "./mix.scss";

header{
    @include bgColor(#04204a);
    // position: absolute;
    z-index: 1;
    width: $full;
    @include mix_padding(20px, 0, 20px, 0);
    nav{
        .navbar-nav{
            .nav-item{
                .headerPhone{
                    border: 2px solid #fff;
                    @include mix_padding(5px, 35px, 5px, 35px);
                    @include mix_radius(30px, 30px, 30px, 30px); 
                    line-height: 35px;
                    font-weight: 500 !important;
                    margin: 0 15px;
                    &:hover{
                        border: 2px solid #f63;
                        background-color: #f63;
                        color: #fff
                    }
                }
                @include mix_margin(0, 0, 0, 20px);
                .nav-link{
                    color: #fff;
                    font-weight: 300;
                    font-size: 1.125rem;
                    transition: 0.3s;
                    @media(max-width: 1366px){
                        font-size: 0.9rem;
                    }
                    &:hover{
                        color: #f63;
                    }
                }
                &:last-child{
                    // border: 2px solid #ff6633;
                    // @include mix_padding(5px, 10px, 5px, 10px);
                    // @include mix_radius(30px, 30px, 30px, 30px);
                    // background-color: #ff6633;
                    .nav-link {
                        font-weight: 500;
                        transition: 0.3s;
                    }
                    &:hover{
                        background: transparent;
                        .nav-link{
                            color: #fff;
                            transition: 0.3s;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 991px) {
        @include mix_padding(10px, 0, 10px, 0);
        nav{
            .navbar-toggler{
                border: 1px solid $baseColor;
                background: #fff;
                span{
                    color: #fff;
                }
                &:focus{
                    box-shadow: none;
                }
            }
            .nav-item{
                @include mix_margin(0, 0, 0, 0 !important);
                width: $full;
                background: #05326C;
                border: 1px solid #04204a;
                .nav-link{
                    display: block;
                    @include mix_padding(15px, 0, 15px, 0);
                    text-align: center;
                }
                &:last-child{
                    background: $trans;
                    @include mix_margin(20px, 0, 0, 0 !important);
                    &:hover{
                        background: #fff;
                        .nav-link{
                            color: #000;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 991px){
    .navbar-toggler{
        .navbar-toggler-icon{ color: #fff !important;}
    }
}