@import "../style/mix.scss";

.discoverBlock{
    @include bgColor(#0d1c30);
    @include mix_padding(150px, 0, 100px, 0);
    .discoverContent{
        h3{
            @include heading(30px, 40px, 700);
            color: #fff;
        }
        p{
            color: #fff;
            @include mix_margin(15px, 0, 15px, 0);
        }
        a{
            background: $baseColor;
            font-size: 22px;
            color: #fff;
            border-radius: 50px;
            font-weight: 600;
            padding: 10px 40px 10px 40px;
            line-height: 100%;
            @include mix_margin(15px, 0, 0, 0);
            display: inline-block;
            &:hover{
                @include bgColor(#194185);
            }
        }
        a:hover{
            color: #fff;
            cursor: pointer;
        }
    }
    @media(max-width: 1024px){
        img{
            margin-bottom: 30px;
        }
        .discoverContent{
            h3{
                @include heading(26px, 40px, 700);
            }
            p{
                line-height: 24px;
            }
        }
    }
    @media(max-width: 991px){
        .discoverContent{
            text-align: center;
            h3{
                @include heading(22px, 40px, 700);
            }
            p{
                line-height: 24px;
            }
        }
    }
}