@import "./mix.scss";

.OnlineInformation{
    @include bgColor(#194185);
    position: relative;
    @include mix_padding(50px, 15px, 50px, 15px);
    &:before{
        position: absolute;
        content: "";
        width: 100%;
        height: 50px;
        background: #194185;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        top: -49px;
        left:0;
    }
    .lrg-number{
        @include heading(150px, 100%, 700);
    }
    .infoDetails{
        p{
            font-size: 30px;
            font-weight: 300;
            @include mix_margin(0, 0, 15px, 10px);
        }
        ul{
            padding-left: 0;
            li{
                background: #fff;
                border-radius: 3px;
                font-size: 14px;
                @include mix_padding(5px, 10px, 5px, 10px);
                @include mix_margin(10px, 10px, 10px, 10px);
            }
        }
    }
    @media(max-width: 1024px) {
        @include mix_padding(30px, 15px, 30px, 15px);
        .lrg-number{
            @include heading(90px, 100%, 700);
        } 
        .infoDetails {
            p{
                font-size: 24px;
                @include mix_padding(0, 0, 0, 10px);
            }
            ul{
                li{
                    padding: 5px 10px;
                    @include mix_margin(10px, 10px, 10px, 10px);
                }
            }
        }
    }
}