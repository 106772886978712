@import "../../components/scanningflow/style/mix.scss";

.sideBarStick{
    height: 100vh;
    background: #041b3e;
    top: 95px;
    padding-top: 40px;
    position: fixed;
    width: 17%;
    z-index: 9999;
    @media(max-width: 991px){
        width: 25%;
        top: 48px;
    }
    &:before{
        position: absolute;
        top:0;
        height: 0;
    }
    .sideBarNav{
        display:flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        .topSidebar{
            flex-grow: 1;
            padding: 20px;
            overflow: auto;
            max-height: calc(100vh - 280px);
        }
        .btmSidebar{
            flex-grow: 1;
            padding: 20px;
            padding-top: 30px;
            border-top: 1px solid #1368ba;
            height: 145px;
            position: fixed;
            bottom: 0;
            width: 17%;
            @media(max-width: 991px){
                width: 25%;
            }
        }
        .nav{
            .nav-link{
                padding: 0 0 30px;
                color: #fff;
                font-weight: 500;
                transition: 0.1s;
                font-size: 16px;
                img{
                    width: 25px;
                    @include mix_margin(0, 15px, 0, 0)
                }
                &:hover{
                    text-decoration-line: underline !important;
                    text-decoration-thickness: 3px !important;
                    font-weight: 700;
                    transition: 0.1s;
                    font-size: 15px;
                }
                @media(max-width: 1280px){
                    font-size: 14px;
                    &:hover{
                        font-size: 14px;
                    }
                }
            }
            .active-nav-link{
                text-decoration-line: underline !important;
                text-decoration-thickness: 3px !important;
                font-weight: 700;
                // font-size: 18px;
            }
        }
    }
}

