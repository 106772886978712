$segoe: 'Segoe UI', sans-serif;
$baseColor: #1570ef;
$full: 100%;
$trans: transparent;
$darks: #000;

*{
    font-family: $segoe;
}

// Mixin
@mixin bgColor ($bgcolor){
    background: $bgcolor;
}

@mixin heading($fo_si, $line_h, $weight){
    font-family: $segoe;
    font-size: $fo_si;
    line-height: $line_h;
    font-weight: $weight;
}

@mixin mix_padding ($pad_top, $pad_right, $pad_bottom, $pad_left){
    padding: $pad_top  $pad_right  $pad_bottom  $pad_left;
}

@mixin mix_margin ($mar_top, $mar_right, $mar_bottom, $mar_left) {
    margin: $mar_top $mar_right $mar_bottom $mar_left;
}

@mixin mix_radius ($rad_top, $rad_right, $rad_bottom, $rad_left) {
    border-radius: $rad_top $rad_right $rad_bottom $rad_left;
}