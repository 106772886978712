@import "../style/mix.scss";

footer{
    .footerTop{
        @include bgColor($darks);
        @include mix_padding(50px, 0, 50px, 0);
        .footerBrand{
            @media(max-width: 991px){
                display: flex;
                flex-direction: column;
                align-items: center; 
            }
            img{
                max-width: 300px;
                margin-bottom: 50px;
            }
            ul{
                padding: 0;
                li{
                    margin-bottom: 10px;
                    a{
                        font-size: 20px;
                        color: #fff;
                    }
                    svg{
                        margin-right: 10px;
                    }
                }
            }
        }
        .footerNav{
            h3{
                margin-top: 85px;
                font-size: 16px;
                color: #fff;
            }
            ul{
                padding: 0;
                margin-top: 30px;
                li{
                    a{
                        color: #fff;
                        font-size: 16px;
                    }
                    & + li{
                        margin-top: 10px;
                    }
                }
            }
            @media(max-width: 991px){
                display: flex;
                flex-direction: column;
                align-items: center;
                h3{
                    margin-top: 20px;
                }
                ul{
                    display: flex;
                    align-items: center;
                    margin-top: 15px;
                    li{
                        & + li{
                            margin-top: 0;
                        }
                    }
                }
            }
        }
        .footerRightContent{
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            @media(max-width: 991px){
                align-items: center; 
            }
            .footerSocial{
                ul{
                    display: flex;
                    flex-direction: row;
                    li{
                        a{
                            width: 30px;
                            height: 30px;
                            background-color: #fff;
                            display: block;
                            border-radius: 50%;
                            text-align: center;
                            line-height: 30px;
                            font-size: 20px;
                        }
                        & + li{
                            margin-left: 15px;
                        }
                    }
                }
            }
            .secureBlock{
                 ul{
                    margin-top: 30px;
                    display: flex;
                    li{
                        margin-left: 10px;
                    }
                 }
            }
            .footerSecure{
                padding-top: 20px;
                ul{
                    display: flex;
                    flex-direction: row;
                    li{
                        margin-left: 10;
                        padding: 0 10px;
                        &:first-child{
                            border-right: 1px solid #fff;
                        }
                        a{
                            line-height: 100%;
                            color: #fff;
                        }
                    }
                }
            }
            .copyFooter{
                color: #fff;
                margin-top: 20px;
            }
        }
        @media(max-width: 767px) {
            h3{
                text-align: center;
            }
            ul{
                li{
                    text-align: center;
                }
            }
        }
    }
    .footerBottom {
        @include bgColor($darks);
        @include mix_padding(30px, 0, 30px, 0);
        p{
            color: #fff;
        }
        @media(max-width: 767px) {
            p{
                text-align: center;
            }
        }
    }
}