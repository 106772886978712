@import url('https://fonts.cdnfonts.com/css/segoe-ui-4');
@import './style/Header.scss';
@import './style/Banner.scss';
@import './style/OnlineInfo.scss';

.formScannig{
    .f-search{
        margin: 0px;
        padding: 0;
        border: 1px solid #000;
        background: #fff;
        input{
            border-radius: 2em;
            &::placeholder{
                color: #9CA3AF;
                font-weight: 700;
            }
        }
    }
}