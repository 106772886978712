@import "../style/mix.scss";

.privacyBlock{
    background: rgb(21, 112, 239);
    background: -moz-linear-gradient(top, rgba(21, 112, 239, 1) 1%, rgba(30, 65, 160, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(21, 112, 239, 1) 1%, rgba(30, 65, 160, 1) 100%);
    background: linear-gradient(to bottom, rgba(21, 112, 239, 1) 1%, rgba(30, 65, 160, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1570ef', endColorstr='#1e41a0', GradientType=0);
    @include mix_margin(50px, 0, 0, 0);
    @include mix_padding(50px, 0, 50px, 0);
    position: relative;
    &:before{
        position: absolute;
        content: "";
        width: 100%;
        height: 50px;
        background: $baseColor;
        clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        top: -49px;
        left: 0;
    }
    &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 50px;
        background: #1E41A0;
        clip-path: polygon(50% 100%, 0 0, 100% 0);
        bottom: -49px;
        left: 0;
    }
    h2{
        @include heading(40px, 100%, 700);
        color: #fff;
        @include mix_margin(0, 0, 60px, 0)
    }
    .eachArrow{
        .arrowContent{
            @include bgColor(#fff);
            @include mix_margin(20px, 0, 20px, 0);
            @include mix_padding(30px, 30px, 30px, 30px);
            border-right: 15px solid #83b6ff;
            position: relative;
            transition: 0.2s;
            &:after{
                position: absolute;
                content: "";
                right: -15px;
                top: 50%;
                transform: translateY(-50%);
                width: 15px;
                height: 15px;
                @include bgColor(#fff);
                clip-path: polygon(100% 50%, 0 100%, 0 0);
                transition: 0.2s;
            }
            &:hover{
                box-shadow:
  0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12);
                &:after{
                    position: absolute;
                    content: "";
                    right: -1px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 15px;
                    height: 15px;
                    @include bgColor(#83b6ff);
                    clip-path: polygon(0 50%, 100% 100%, 100% 0);
                    transition: 0.2s;
                }
            }
            p{
                @include mix_margin(0, 0, 0, 30px);
                color: #194185;
                font-size: 18px;
                line-height: 30px;
                b{
                    color: black;
                }
            }
        }
    }
    .privacyCarousel{
        .eachSliderItem{
            @include bgColor(#fff);
            @include mix_padding(30px, 30px, 30px, 30px);
            border-top: 10px solid #2c94ff;
            p{
                font-size: 18px;
                line-height: 30px;
                color: #657087;
                @include mix_padding(0, 0, 0, 30px);
                
            }
            svg{
                opacity: 0.5;
            }
            .quoteUser{
                h4{
                    @include heading(24px, 30px, 600);
                    @include mix_margin(50px, 0, 10px, 0);
                    color: #222;
                    svg{
                        @include mix_margin(0, 20px, 0, 0);
                    }
                    @media(max-width: 991px){
                        @include heading(20px, 30px, 600);
                    }
                }
            }
        }
        .owl-nav{
            @include mix_margin(30px, 0, 0, 0);
            button{
                width: 30px;
                height: 30px;
                @include mix_margin(0, 10px, 0, 10px);
                @include bgColor(#fff);
                span{
                    line-height: 25px;
                    text-align: center;
                    font-weight: 600;
                    font-size: 30px;
                }
                &:hover{
                    @include bgColor(#0D1C30);
                }
            }
        }
    }
    @media(max-width: 1024px) {
        
    }
}