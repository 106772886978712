button{
    border: none;
    background: none;
    border-radius: 10px;
    padding: 10px 15px;
}

.grayBtn{
  background-color: #94B2C0 !important;
  border-color: #94B2C0 !important;
}
.btn-theme{
  background-color: #f63 !important;
  border-color: #f63 !important;
  font-weight: 600 !important;
  &:hover{
    background-color: #FF4D12 !important;
  }
}

.signBtn{
  &:hover{
    text-decoration: underline;
  }
}

.log-reg-header {
    padding: 30px 30px 100px;
    background: #051F4B;
    .welcomeHeads{
      h2 {
        color: #fff;
        font-weight: 700;
        font-size: 2.5rem;
      }
    }
    p {
      color: #fff;
    }
    .topBarLogo {
      padding-bottom: 50px;
    }
  }
  .subButton{
    height: 50px;
  }
  .topBarLogo{
    padding: 0 0 ;
    justify-content: flex-start;
    display: flex;

    @media(max-width: 991px){
       display: flex;
       justify-content: center;
       padding-bottom: 20px !important;
    }
  }
  .log-reg-footer {
    background: #fff;
    height: auto;
    border-radius: 10px;
    width: 500px;
    margin: -80px auto 0;
    box-shadow: 0px 0px 5px #0000002e;
    padding: 40px 20px;
    height: 430px;
    @media(max-width: 991px){
        max-width: 100%;
        height: 330px;
        width: 100%;
    }
  }