@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.5.0/css/font-awesome.css');

*{
  margin:0;
  padding: 0;
  box-sizing: border-box;
}
a,a:hover{
  text-decoration: none !important;
  transition: 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
}
p,a, h1,h2,h3,h4,h5,h6{
  margin: 0;
  padding: 0;
  line-height: 1;
}
ul{
  list-style: none;
  padding: 0;
}
a:focus, a:hover, select:focus, input:focus{
  outline: none !important;
  box-shadow: none !important;
  border-color: none !important;
}


body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
